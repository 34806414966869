var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "chart-wrap" } },
    [
      _c(
        "CButton",
        {
          attrs: { color: "warning", size: "sm" },
          on: { click: _vm.replaceData }
        },
        [_vm._v("変更")]
      ),
      _c("apexchart", {
        attrs: {
          width: "70%",
          height: "400",
          type: "bar",
          options: _vm.chartOptions,
          series: _vm.series
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }